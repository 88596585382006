const data = [
  // {
  //   id: 1,
  //   text: 'home',
  //   url: '/',
  // },
  {
    id: 2,
    text: 'about',
    url: '#about',
  },
  {
    id: 3,
    text: 'work',
    url: '#work',
  },

  {
    id: 4,
    text: 'projects',
    url: '#projects',
  },

  {
    id: 5,
    text: 'contact',
    url: '#contact',
  },
];

export default data;
